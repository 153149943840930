import { ThemeProvider } from '@emotion/react';
import type { FC } from 'react';
import { SiteUikitAppWrapper, useThemeProvider } from '@volvo/vce-package-site-uikit';
import type { UikitWrapperProps } from '../../../UikitWrapper';

export const FederationUikitWrapper: FC<UikitWrapperProps> = ({ children }) => {
  const { theme } = useThemeProvider();

  return (
    <ThemeProvider theme={theme}>
      <SiteUikitAppWrapper>{children}</SiteUikitAppWrapper>
    </ThemeProvider>
  );
};
