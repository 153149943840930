import styled from '@emotion/styled';
import { Button } from '@volvo/vce-uikit';

export const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  flex-direction: row;
  padding: 16px 32px;
  margin: 24px;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.bg};
  font-size: 14px;
`;

export const Info = styled.div`
  flex-grow: 1;
  line-height: 18px;
  letter-spacing: 0.28px;
`;

export const GoToFlowsButton = styled(Button)`
  flex-grow: 0;
  min-width: 170px;
  font-size: 14px;
`;
