import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
  type PropsWithChildren,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterContext, type ExternalSource } from '@volvo/vce-package-site-uikit';
import { Button, EmptyState, toast, useAuth } from '@volvo/vce-uikit';
import { GqlErrorDisplay } from '../../components/common/error/GqlErrorDisplay';
import { CenteredSpinner } from '../../components/common/loading/CenteredSpinner';
import {
  SortDirection,
  useQueryMaterialFlowsBySiteMapId,
  useQueryUnassignedSiteLoadTicketsLazyQuery,
  type SiteLoadTicketsFilterBy,
  type SiteLoadTicketsGroupByFlowIdOrderBy,
} from '../../gql-types/generated-types-super-graph';
import { getApolloClient } from '../../gql/apollo-client';
import type { ExternalReference } from '../../types';
import { Filter } from './filter/Filter';
import {
  getIngestionServiceName,
  getIngestionServiceType,
  getSystemTypeFilterValue,
} from './getTypes';
import { GoToFlows } from './go-to-flows/GoToFlows';
import { Center, Container } from './styles';
import { Table } from './table/Table';
import type { SortingState, SourceFlow } from './types';

const defaultSortingState = { id: 'count', desc: true };

type QueryResult = {
  flows: SourceFlow[];
  page: number;
  hasMorePages: boolean;
};

export type Props = { siteId: string; companyId?: string; pageSize: number };

export const UnassignedLoadTicketsContent: FC<PropsWithChildren<Props>> = ({
  siteId,
  companyId,
  pageSize,
  children,
}) => {
  const { t } = useTranslation();
  const { getToken } = useAuth();
  const { filterState } = useFilterContext();

  const client = useMemo(() => getApolloClient(getToken), [getToken]);

  const [queryUnassingedSiteLoadTickets, { loading: loadingTickets }] =
    useQueryUnassignedSiteLoadTicketsLazyQuery({ client });

  const { data: flowCollection, loading: loadingFlowCollection } = useQueryMaterialFlowsBySiteMapId(
    {
      variables: { siteId },
    },
  );

  const hasMore = useRef(true);
  const currentPage = useRef<number>(0);

  const [loading, setLoading] = useState(true);
  const [sourceFlows, setSourceFlows] = useState<SourceFlow[]>([]);
  const [sortingState, setSortingState] = useState<SortingState>({
    sorting: [defaultSortingState],
  });

  const loadUnassignedTickets = useCallback(
    async (page: number, from?: Date, to?: Date): Promise<QueryResult> => {
      const filterBy: SiteLoadTicketsFilterBy = {
        flowId: null,
        siteId,
      };

      if (filterState.editableState.externalSource !== 'All') {
        filterBy.source = getIngestionServiceName(
          filterState.editableState.externalSource as ExternalSource,
        );
      }

      if (filterState.editableState.searchValue) {
        filterBy.sourceFlowId = filterState.editableState.searchValue;
      }

      if (filterState.editableState.systemType !== 'All') {
        filterBy.scaleType = getSystemTypeFilterValue(filterState.editableState.systemType);
      }

      const validSortValues = ['count', 'sourceTicketFlowId'];

      const orderBy: SiteLoadTicketsGroupByFlowIdOrderBy = validSortValues.includes(
        sortingState.sorting[0].id,
      )
        ? {
            [sortingState.sorting[0].id]: sortingState.sorting[0].desc
              ? SortDirection.Desc
              : SortDirection.Asc,
          }
        : {
            [defaultSortingState.id]: defaultSortingState.desc
              ? SortDirection.Desc
              : SortDirection.Asc,
          };

      const { data } = await queryUnassingedSiteLoadTickets({
        variables: {
          skip: page * pageSize,
          take: pageSize,
          filterBy,
          from,
          to,
          orderBy,
        },
      });

      if (!data) {
        return {
          flows: [],
          page: 0,
          hasMorePages: false,
        };
      }

      const flows = data.siteLoadTickets.groupBySourceFlowId.groups.map((group) => ({
        sourceTicketFlowId: group.sourceTicketFlowId ?? 'undefined',
        ingestionService: getIngestionServiceType(group.source),
        count: group.count,
        systemType: group.scaleType,
        lastTicket: group.lastTicket,
      }));

      return {
        flows: flows,
        page: page + 1,
        hasMorePages: data?.siteLoadTickets.groupBySourceFlowId.hasNextPage ?? false,
      };
    },
    [
      filterState.editableState.externalSource,
      filterState.editableState.searchValue,
      filterState.editableState.systemType,
      pageSize,
      queryUnassingedSiteLoadTickets,
      siteId,
      sortingState.sorting,
    ],
  );

  const findAssignedFlows = useCallback(
    (flows: SourceFlow[]) => {
      if (!flowCollection) {
        return;
      }

      flows.forEach((flow) => {
        const connectedFlow = flowCollection?.siteMaps.site?.siteMaterialFlows.edges.find(
          ({ node }) =>
            node.externalReferences?.some(
              (er: ExternalReference) =>
                er.value === flow.sourceTicketFlowId && er.type === flow.ingestionService,
            ),
        )?.node;

        if (!connectedFlow) {
          return;
        }
        flow.connectedFlowNames = [connectedFlow.name ?? ''];
      });
    },
    [flowCollection],
  );

  useEffect(() => {
    const from = filterState.editableState.startDate;
    const to = filterState.editableState.endDate;
    setLoading(true);
    setSourceFlows([]);
    loadUnassignedTickets(0, from.toDate(), to.toDate())
      .then((result) => {
        currentPage.current = result.page;
        hasMore.current = result.hasMorePages;

        findAssignedFlows(result.flows);
        setSourceFlows(result.flows);
      })
      .catch((error) => {
        toast.error(<GqlErrorDisplay errors={error.graphQLErrors} />);
      })
      .finally(() => setLoading(false));
  }, [filterState.editableState, findAssignedFlows, loadUnassignedTickets]);

  const loadMore = () => {
    if (loadingTickets || loadingFlowCollection || !sourceFlows || !hasMore.current) return;
    loadUnassignedTickets(
      currentPage.current,
      filterState.editableState.startDate.toDate(),
      filterState.editableState.endDate.toDate(),
    )
      .then((result) => {
        currentPage.current = result.page;
        hasMore.current = result.hasMorePages;

        const flows = Object.values(result.flows);
        findAssignedFlows(flows);
        setSourceFlows((prev) => [...prev, ...flows]);
      })
      .catch(console.error);
  };

  return (
    <Container>
      <Filter />
      {companyId ? <GoToFlows siteId={siteId} companyId={companyId} /> : null}
      <Table
        loadTickets={sourceFlows}
        sortingState={sortingState}
        setSortingState={setSortingState}
      >
        {children}
      </Table>
      {hasMore.current && (
        <Center>
          <Button variant="secondary" onClick={loadMore}>
            {t('table.load-more-button')}
          </Button>
        </Center>
      )}
      {loading && <CenteredSpinner />}
      {!loading && !sourceFlows.length && (
        <Center>
          <EmptyState headingText={t('no-data')} bodyText={t('no-data-body')} icon="info" />
        </Center>
      )}
    </Container>
  );
};
