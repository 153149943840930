import type { TFunction } from 'i18next';
import type { ExternalSource } from '@volvo/vce-package-site-uikit';
import { ExternalReferenceTypes } from '../../gql-types/generated-types-super-graph';

export const getIngestionServiceType = (ingestionService: string): ExternalReferenceTypes => {
  switch (ingestionService) {
    case 'TAMTRON_INGESTION':
      return ExternalReferenceTypes.Tamtron;
    case 'FLINTAB_INGESTION':
      return ExternalReferenceTypes.Flintab;
    case 'LOADRITE_INGESTION':
      return ExternalReferenceTypes.Loadrite;
    case 'ASSIST_INGESTION':
      return ExternalReferenceTypes.VolvoAssist;
    case 'OPERATOR_APP':
      return ExternalReferenceTypes.OperatorApp;
  }

  return ExternalReferenceTypes.Flintab;
};

export const getIngestionServiceName = (ingestionService: ExternalSource | undefined) => {
  switch (ingestionService) {
    case ExternalReferenceTypes.Tamtron:
      return 'TAMTRON_INGESTION';
    case ExternalReferenceTypes.Flintab:
      return 'FLINTAB_INGESTION';
    case ExternalReferenceTypes.Loadrite:
      return 'LOADRITE_INGESTION';
    case ExternalReferenceTypes.VolvoAssist:
      return 'ASSIST_INGESTION';
    case ExternalReferenceTypes.OperatorApp:
      return 'OPERATOR_APP';
  }

  return undefined;
};

export const getSystemTypeFilterValue = (systemType: string | undefined) => {
  switch (systemType) {
    case 'onboard-weighing':
      return 'OBW';
    case 'weighbridge':
      return 'WEIGH_BRIDGE';
    // Add more scale types here as needed
  }

  return undefined;
};

export const getSystemType = (scaleType: string, t: TFunction) => {
  switch (scaleType) {
    case 'OBW':
      return t('load-tickets.unmapped-table.system-types.onboard-weighing');
    case 'WEIGHBRIDGE':
      return t('load-tickets.unmapped-table.system-types.weighbridge');
    default:
      return t('load-tickets.unmapped-table.external-sources.other');
  }
};
