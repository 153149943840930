import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant, SystemIcon } from '@volvo/vce-uikit';
import { Container, GoToFlowsButton, Info } from './styles';

export const GoToFlows: FC<{ siteId: string; companyId: string }> = ({ siteId, companyId }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SystemIcon icon="info" size={32} />
      <Info>{t('load-tickets.go-to-flows-info')}</Info>
      <GoToFlowsButton
        variant={ButtonVariant.Secondary}
        iconEnd="chevron-right"
        onClick={() => {
          window.location.assign(`/companies/${companyId}/sites/${siteId}/map/flow/`);
        }}
      >
        {t('load-tickets.go-to-flows-button-text')}
      </GoToFlowsButton>
    </Container>
  );
};
