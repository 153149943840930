import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  padding: 12px 24px;
  height: 64px;
  width: 100%;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.bgBase};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderSubtle};
`;
