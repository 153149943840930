import styled from '@emotion/styled';

export const Container = styled.div`
  margin: 24px;
  flex-grow: 1;
  overflow: auto;
`;

export const IconWithLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
